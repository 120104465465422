const data = [
  { day: "Mon", sent: 145, delivered: 140, opened: 98 },
  { day: "Tue", sent: 156, delivered: 151, opened: 112 },
  { day: "Wed", sent: 178, delivered: 173, opened: 127 },
  { day: "Thu", sent: 184, delivered: 179, opened: 134 },
  { day: "Fri", sent: 162, delivered: 158, opened: 119 },
  { day: "Sat", sent: 134, delivered: 130, opened: 87 },
  { day: "Sun", sent: 127, delivered: 123, opened: 76 },
];

export function EmailChart() {
  const maxValue = Math.max(
    ...data.flatMap((d) => [d.sent, d.delivered, d.opened])
  );
  const height = 200;

  return (
    <div class="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700">
      <h3 class="text-lg font-semibold mb-6 dark:text-white">
        Weekly Email Activity
      </h3>
      <div class="relative h-[200px]">
        <div class="absolute inset-0">
          {data.map((day, i) => {
            const x = (i / (data.length - 1)) * 100;
            return (
              <div
                class="absolute bottom-0 transform -translate-x-1/2"
                style={{ left: `${x}%`, height: "100%" }}
              >
                <div class="relative h-full">
                  {/* Sent */}
                  <div
                    class="absolute bottom-0 w-2 bg-blue-200 dark:bg-blue-500/30 rounded-t"
                    style={{
                      height: `${(day.sent / maxValue) * height}px`,
                      left: "-12px",
                    }}
                  />
                  {/* Delivered */}
                  <div
                    class="absolute bottom-0 w-2 bg-green-200 dark:bg-green-500/30 rounded-t"
                    style={{
                      height: `${(day.delivered / maxValue) * height}px`,
                      left: "-4px",
                    }}
                  />
                  {/* Opened */}
                  <div
                    class="absolute bottom-0 w-2 bg-purple-200 dark:bg-purple-500/30 rounded-t"
                    style={{
                      height: `${(day.opened / maxValue) * height}px`,
                      left: "4px",
                    }}
                  />
                </div>
                <div class="absolute bottom-0 transform translate-y-full mt-2 text-sm text-gray-600 dark:text-gray-400">
                  {day.day}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div class="flex justify-center gap-6 mt-8">
        <div class="flex items-center">
          <div class="w-3 h-3 bg-blue-200 dark:bg-blue-500/30 rounded mr-2" />
          <span class="text-sm text-gray-600 dark:text-gray-400">Sent</span>
        </div>
        <div class="flex items-center">
          <div class="w-3 h-3 bg-green-200 dark:bg-green-500/30 rounded mr-2" />
          <span class="text-sm text-gray-600 dark:text-gray-400">
            Delivered
          </span>
        </div>
        <div class="flex items-center">
          <div class="w-3 h-3 bg-purple-200 dark:bg-purple-500/30 rounded mr-2" />
          <span class="text-sm text-gray-600 dark:text-gray-400">Opened</span>
        </div>
      </div>
    </div>
  );
}
